import React from 'react'
import { Card } from 'antd';
import Scroll from 'react-scroll';
const RightSideDetailExam = (props)=> {
    const { scrollStatus, testBlueprints, answerTaker, testFormItems, testFormChildItems, showMenuItem } = props;
    let itemNumRight = 0;
    const { scroller } = Scroll;
    
    const onMenuClick = (itemId) => {
        scroller.scrollTo(`ScrollToItem${itemId}`, {
          duration: 300,
          delay: 0,
          smooth: true,
          offset: -70, // Scrolls to element + 50 pixels down the page
        });
        showMenuItem(false);
    }

    return (
        <div className={`${scrollStatus ? 'menu-index-item' : ''}`}>
            <Card className="x-card card-menu" title="Mục lục">
                {testBlueprints.map(
                    (testBlueprint, testBlueprintIndex) => (
                        
                    <React.Fragment key={testBlueprintIndex}>
                        {testFormItems.map((item) => {
                            if (item.test_blueprint_id == testBlueprint.id) {
                                if (item.is_parent) {
                                    return testFormChildItems.map(
                                        (childItem) => {
                                        if (childItem.parent_id == item.id) {
                                            let correct = 0;
                                            if (
                                            answerTaker[`${childItem.id}`] &&
                                            answerTaker[`${childItem.id}`].data
                                            ) {
                                            if (
                                                answerTaker[`${childItem.id}`] &&
                                                answerTaker[`${childItem.id}`]
                                                .correct_answer == 0
                                            ) {
                                                correct = 2;
                                            } else {
                                                correct = 1;
                                            }
                                            }
                                            itemNumRight++;

                                            return (
                                            <div
                                                onClick={() => {
                                                    onMenuClick(childItem.id);
                                                }}
                                                className={
                                                `${
                                                    correct == 0
                                                    ? ''
                                                    : correct == 1
                                                    ? 'correct'
                                                    : 'wrong'
                                                }` +
                                                ' group-choice mr-3 mb-3 index-item'
                                                }
                                                key={childItem.id}
                                            >
                                                <span className="option-choice">
                                                {itemNumRight}
                                                </span>
                                            </div>
                                            );
                                        }

                                        return '';
                                        },
                                    );
                                }
                                
                                let correct = 0;
                                if (answerTaker[item.id]?.data) {
                                    if (answerTaker[item.id]?.correct_answer == 0) {
                                        if (answerTaker[item.id]?.type == 3) {
                                            const dataAnswer = JSON.parse(answerTaker[item.id].data);
                                            correct = (dataAnswer.content && dataAnswer.attachment) ? 1 : 2;
                                        } else {
                                            correct = 2;
                                        }
                                    } else {
                                        correct = 1;
                                    }
                                }

                                itemNumRight++;

                                return (
                                <div
                                    onClick={() => {
                                        onMenuClick(item.id);
                                    }}
                                    className={`${
                                    correct == 0
                                        ? ''
                                        : correct == 1
                                        ? 'correct'
                                        : 'wrong'
                                    } ' group-choice mr-3 mb-3 index-item`}
                                    key={item.id}
                                >
                                    <span className="option-choice">
                                    {itemNumRight}
                                    </span>
                                </div>
                                );
                            }

                            return '';
                        })}
                    </React.Fragment>
                    ),
                )}
            </Card>
        </div>
    )
}
export default RightSideDetailExam