import { array } from 'prop-types';
import moment from 'moment';
import { ktGetTrackingData, ktSaveTrackingData } from 'core/ui';

export function fadeOut(elementId) {
  const fadeTarget = document.getElementById(elementId);
  var fadeEffect = setInterval(() => {
    if (!fadeTarget.style.opacity) {
      fadeTarget.style.opacity = 1;
    }
    if (fadeTarget.style.opacity > 0) {
      fadeTarget.style.opacity -= 0.1;
    } else {
      clearInterval(fadeEffect);
      fadeTarget.remove();
    }
  }, 50);
}
export function getErrorFields(responseData) {
  const data = {};
  responseData.forEach((element) => {
    data[element.field] = element.message;
  });

  return data;
}
export function getErrorMessages(responseData) {
  let errors = false;
  let message = false;
  if (
    typeof responseData.errors != 'undefined' &&
    Object.keys(responseData.errors).length > 0
  ) {
    errors = responseData.errors;
  } else if (typeof responseData.message == 'string') {
    message = responseData.message;
  } else if (typeof responseData == 'object') {
    errors = responseData;
  }
  if (errors) {
    const errorMessages = Object.values(errors);

    return errorMessages.join('\n');
  }
  if (message) {
    return message;
  }

  return '';
}

export function getBase64Image(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });
}

export function strip_tags(html, allowed_tags) {
  allowed_tags = allowed_tags.trim();

  if (allowed_tags) {
    allowed_tags = allowed_tags.split(/\s+/).map((tag) => `/?${tag}`);
    allowed_tags = `(?!${allowed_tags.join('|')})`;
  }

  return html.replace(new RegExp(`(<${allowed_tags}.*?>)`, 'gi'), '');
}

export function getTimeDifferences(date1, date2) {
  const d = new Date(date2).getTime() - new Date(date1).getTime();
  const weekdays = Math.floor(d / 1000 / 60 / 60 / 24 / 7);
  const days = Math.floor(d / 1000 / 60 / 60 / 24 - weekdays * 7);
  const hours = Math.floor(d / 1000 / 60 / 60 - weekdays * 7 * 24 - days * 24);
  const minutes = Math.floor(
    d / 1000 / 60 - weekdays * 7 * 24 * 60 - days * 24 * 60 - hours * 60,
  );
  const seconds = Math.floor(
    d / 1000 -
      weekdays * 7 * 24 * 60 * 60 -
      days * 24 * 60 * 60 -
      hours * 60 * 60 -
      minutes * 60,
  );
  let res = `${hours > 9 ? hours : `0${hours}`}:${
    minutes > 9 ? minutes : `0${minutes}`
  }:${seconds > 9 ? seconds : `0${seconds}`}`;
  res = days > 0 ? `${days} ngày ${res}` : res;

  return res;
}

export function formatReportNumber(point) {
  return Number.isNaN(parseInt(point)) ? '-' : point;
}
export function colorChart(type) {
  if (type == 'hover') {
    return [
      'rgba(254,93,85,0.75)',
      'rgba(253,191,108,0.75)',
      'rgba(231,217,84,0.75)',
      'rgba(106,221,105,0.75)',
      'rgba(55,166,72,0.75)',
    ];
  }

  return [
    'rgb(254,93,85)',
    'rgb(253,191,108)',
    'rgb(231,217,84)',
    'rgb(106,221,105)',
    'rgb(55,166,72)',
  ];
}
export function colorChartList(type) {
  if (type == 'hover') {
    return [
      'rgba(254,93,85,0.45)',
      'rgba(253,191,108,0.45)',
      'rgba(231,217,84,0.45)',
      'rgba(99,194,222,0.45)',
      'rgba(106,221,105,0.45)',
      'rgba(55,166,72,0.45)',
    ];
  }

  return [
    'rgb(254,93,85)',
    'rgb(253,191,108)',
    'rgb(231,217,84)',
    'rgb(99,194,222,1)',
    'rgb(106,221,105)',
    'rgb(55,166,72)',
  ];
}
export function colorChartList1(type) {
  if (type == 'hover') {
    return [
      'rgb(37, 255, 9, 0.45)',
      'rgb(90, 252, 8, 0.45)',
      'rgb(144, 249, 7, 0.45)',
      'rgb(196, 246, 6, 0.45)',
      'rgb(243, 238, 5, 0.45)',
      'rgb(240, 182, 4, 0.45)',
      'rgb(237, 126, 3, 0.45)',
      'rgb(234, 71, 2, 0.45)',
      'rgb(231, 17, 1, 0.45)',
      'rgb(229, 0, 35, 0.45)',
    ];
  }

  return [
    '#25FF09',
    '#5AFC08',
    '#90F907',
    '#C4F606',
    '#F3EE05',
    '#F0B604',
    '#ED7E03',
    '#EA4702',
    '#E71101',
    '#E50023',
  ];
}
export function getWindowFunction(data, windows) {
  let res = -1;
  for (let index = 0; index < windows.length; index++) {
    const element = windows[index];
    if (data >= element) {
      res++;
    }
  }

  return res;
}
export function TestBlueprintSortItems(data) {
  data.sort((a, b) => {
    if (a.order === b.order) {
      return a.id - b.id;
    }

    return a.order > b.order ? 1 : -1;
  });

  const tree = (function (data, root) {
    const r = [];
    const o = {};
    data.forEach((a) => {
      o[a.id] = { data: a, children: o[a.id] && o[a.id].children };
      if (a.parent_id === root) {
        r.push(o[a.id]);
      } else {
        o[a.parent_id] = o[a.parent_id] || {};
        o[a.parent_id].children = o[a.parent_id].children || [];
        o[a.parent_id].children.push(o[a.id]);
      }
    });

    return r;
  })(data, null);
  const sorted = tree.reduce(
    (function traverse(level) {
      return function (r, a) {
        a.data.level = level;

        return r.concat(
          a.data,
          (a.children || []).reduce(traverse(level + 1), []),
        );
      };
    })(0),
    [],
  );

  sorted.forEach((item) => {
    item.item_level = item.item_level ? JSON.parse(item.item_level) : [];
    item.item_quantity = item.item_quantity
      ? JSON.parse(item.item_quantity)
      : [];
    item.item_point = item.item_point ? JSON.parse(item.item_point) : [];

    const mapObj = {};
    item.item_quantity.forEach((iq, idx) => {
      mapObj[item.item_level[idx]] = iq;
    });
    item.item_quantity = mapObj;

    const mapObj2 = {};
    item.item_point.forEach((iq, idx) => {
      mapObj2[item.item_level[idx]] = iq;
    });
    item.item_point = mapObj2;
  });

  return sorted;
}
export function getStatus() {
  return [
    { id: 1, name: 'Đã thẩm định' },
    { id: 2, name: 'Chờ thẩm định' },
    { id: 3, name: 'Không đạt thẩm định' },
  ];
}
export function getChoiceLabel() {
  return {
    1: 'A',
    2: 'B',
    3: 'C',
    4: 'D',
    5: 'E',
    6: 'F',
    7: 'G',
    8: 'H',
    9: 'I',
    10: 'J'
  };
}
export function getGrade() {
  return [
    { key: 'Khối 6', value: 1 },
    { key: 'Khối 7', value: 2 },
    { key: 'Khối 8', value: 3 },
    { key: 'Khối 9', value: 4 },
    { key: 'Khối 10', value: 5 },
    { key: 'Khối 11', value: 6 },
    { key: 'Khối 12', value: 7 },
  ];
}
export function getArea(arr = array()) {
  let sum = 0;
  if (arr.length > 1) {
    for (let index = 0; index < arr.length; index++) {
      if (arr.length - 1 == index) {
        sum += arr[index] * arr[0];
      } else {
        sum += arr[index] * arr[index + 1];
      }
    }
    sum /= 2;
  }

  return sum;
}

/**
 * @param {*} dateTime
 * @param {*} maxTimeCountDown Default 30 phút. Nếu quá số trên thì trả về null
 * @returns
 */
export function countDownTimer(
  dateTime,
  currentTimestamp,
  // currentTimestamp = Date.now(),
  maxTimeCountDown = 1800000,
) {
  const current = moment(currentTimestamp);
  const target = moment(dateTime);

  if (target > current && target - current < maxTimeCountDown) {
    return moment()
      .startOf('day')
      .milliseconds(target - current)
      .format('mm:ss');
  }

  return null;
}

export function timeRemaining(time_start, time_end, time_limit) {
  let timeEnd = time_end;
  if (time_limit > 0) {
    const addTime = moment(time_start)
      .add(time_limit, 'minutes')
      .format('YYYY-MM-DD HH:mm:ss');
    if (addTime < time_end) {
      timeEnd = addTime;
    }
  }
  if (moment(timeEnd) < moment()) {
    return { status: false };
  }
  let duration = parseInt(
    moment.duration(moment().diff(moment(time_start))).asMinutes(),
  );
  const minuteMax = parseInt(
    moment.duration(moment(timeEnd).diff(moment(time_start))).asMinutes(),
  );
  duration = (duration / minuteMax) * 100;

  return {
    duration: parseFloat(duration).toFixed(2),
    status: true,
    data: getTimeDifferences(
      moment().format('MMMM DD, YYYY HH:mm:ss'),
      moment(timeEnd).format('MMMM DD, YYYY HH:mm:ss'),
    ),
  };
}

export function formatProfile(lstUser, item, id) {
  const userId = item.id_from != id ? item.id_from : item.id_to;
  if (lstUser[`${userId}`]) {
    item.avatar = lstUser[`${userId}`].avatar;
    item.fullname = lstUser[`${userId}`].fullname;
    item.username = lstUser[`${userId}`].username;
  }

  return item;
}
export function isVietnamesePhoneNumber(number) {
  return /(03|05|07|08|09|01[2|6|8|9])+([0-9]{8})\b/.test(number);
}

export function isEmail(email) {
  var re = /\S+@\S+\.\S+/;

  return re.test(email);
}

export function nonAccentVietnamese(str) {
  // // https://gist.github.com/jarvisluong/f01e108e963092336f04c4b7dd6f7e45
  str = str.toLowerCase();

  str = str.replace(/à|á|ạ|ả|ã|â|ầ|ấ|ậ|ẩ|ẫ|ă|ằ|ắ|ặ|ẳ|ẵ/g, 'a');
  str = str.replace(/è|é|ẹ|ẻ|ẽ|ê|ề|ế|ệ|ể|ễ/g, 'e');
  str = str.replace(/ì|í|ị|ỉ|ĩ/g, 'i');
  str = str.replace(/ò|ó|ọ|ỏ|õ|ô|ồ|ố|ộ|ổ|ỗ|ơ|ờ|ớ|ợ|ở|ỡ/g, 'o');
  str = str.replace(/ù|ú|ụ|ủ|ũ|ư|ừ|ứ|ự|ử|ữ/g, 'u');
  str = str.replace(/ỳ|ý|ỵ|ỷ|ỹ/g, 'y');
  str = str.replace(/đ/g, 'd');
  // Some system encode vietnamese combining accent as individual utf-8 characters
  str = str.replace(/\u0300|\u0301|\u0303|\u0309|\u0323/g, ''); // Huyền sắc hỏi ngã nặng
  str = str.replace(/\u02C6|\u0306|\u031B/g, ''); // Â, Ê, Ă, Ơ, Ư

  return str;
}

export function formatVndMoney(number) {
  try {
    return new Intl.NumberFormat('vi-VN', {
      style: 'currency',
      currency: 'VND',
    }).format(number);
  } catch (error) {
    return `${number} đ`;
  }
}

export function stringToFilterString(title) {
  let slug;
  //Đổi chữ hoa thành chữ thường
  slug = title.toLowerCase().trim();

  //Đổi ký tự có dấu thành không dấu
  slug = slug.replace(/á|à|ả|ạ|ã|ă|ắ|ằ|ẳ|ẵ|ặ|â|ấ|ầ|ẩ|ẫ|ậ/gi, 'a');
  slug = slug.replace(/é|è|ẻ|ẽ|ẹ|ê|ế|ề|ể|ễ|ệ/gi, 'e');
  slug = slug.replace(/i|í|ì|ỉ|ĩ|ị/gi, 'i');
  slug = slug.replace(/ó|ò|ỏ|õ|ọ|ô|ố|ồ|ổ|ỗ|ộ|ơ|ớ|ờ|ở|ỡ|ợ/gi, 'o');
  slug = slug.replace(/ú|ù|ủ|ũ|ụ|ư|ứ|ừ|ử|ữ|ự/gi, 'u');
  slug = slug.replace(/ý|ỳ|ỷ|ỹ|ỵ/gi, 'y');
  slug = slug.replace(/đ/gi, 'd');

  //Đổi khoảng trắng thành ký tự gạch ngang
  slug = slug.replace(/\s\s+/g, ' ');

  return slug;
}

export function filterSelectOptions(input, option) {
  return (
    option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0 ||
    stringToFilterString(option.label.toLowerCase()).indexOf(
      stringToFilterString(input).toLowerCase(),
    ) >= 0
  );
}

export function buildRouter(path, parameters = {}) {
  let pathResult = path;

  return Object.keys(parameters).reduce(
    (pathResult, key) => pathResult.replace(`:${key}`, parameters[key]),
    pathResult,
  );
}

export function formatDate(dateTime) {
  return moment(dateTime).format('DD/MM/YYYY');
}

export function isCustomDomain() {
  if(process.env.REACT_APP_USE_BASENAME_DOMAIN){
    let domain = '';
    process.env.REACT_APP_USE_BASENAME_DOMAIN.split('|').forEach(d => {
      if(window.location.hostname.indexOf(d) > -1){
        domain = d;
      }
    })

    return domain;
  }
}

export function clearLocalStorage() {
  const trackingData = ktGetTrackingData();
  console.log(trackingData, 'trackingData');

  localStorage.clear();
  ktSaveTrackingData(trackingData);
}

export function getMatchClozeText(questionBody) {
  const regexClozeText = /{ANSWER_(\d+)}/gi;
  
  return questionBody.match(regexClozeText);
}

export function replaceDatasetValue(str, renderOptions) {
  let s = str;
  if (typeof renderOptions === 'object') {
    const datasetValueKeys = Object.keys(renderOptions?.datasetValue || {});
    if (datasetValueKeys.length > 0) {
      datasetValueKeys.forEach((key) => {
        s = replaceAll(s, `{${key}}`, renderOptions?.datasetValue[key] || '');
      });
    }
  }

  return s;
};

function replaceAll(str, find, replace) {
  return str.replace(new RegExp(escapeRegExp(find), 'g'), replace);
}

function escapeRegExp(string) {
  return string.replace(/[.*+\-?^${}()|[\]\\]/g, '\\$&');
}