import React, { Component } from 'react';
import {
  Col,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
  Button,
} from 'reactstrap';
import { Card } from 'antd';
import Scroll from 'react-scroll';
import QuestionDetail from './QuestionDetail'

const ScrollElement = Scroll.Element;

const markRubricRef = React.createRef();
class ViewItem extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showModalViewRubric: {},
      showModalMarkRubric: {},
    };
  }

  showModalViewRubric(itemId) {
    const { showModalViewRubric } = this.state;
    showModalViewRubric[itemId] = true;
    this.setState({
      showModalViewRubric: showModalViewRubric,
    });
  }

  hideModalViewRubric(itemId) {
    const { showModalViewRubric } = this.state;
    showModalViewRubric[itemId] = false;
    this.setState({
      showModalViewRubric: showModalViewRubric,
    });
  }

  showRubricDetail(item) {
    const response = item.response ? JSON.parse(item.response) : false;
    let rubric;
    if (response.type == 'rubric') {
      rubric = response.rubric;
      /*    if (typeof rubric.criteria == 'string') {
           rubric.criteria = JSON.parse(rubric.criteria)
         } */
    }

    return (
      <div>
        <Button
          size="sm"
          className=""
          onClick={() => this.showModalViewRubric(item.id)}
        >
          Xem rubric
        </Button>
        <Modal
          size="xxl"
          isOpen={this.state.showModalViewRubric[item.id]}
          toggle={() => this.hideModalViewRubric(item.id)}
          centered
        >
          <ModalHeader toggle={() => this.hideModalViewRubric(item.id)}>
            {rubric.name}
          </ModalHeader>
          <ModalBody>
            {/* <RubricDetail rubric={rubric} showName={false} showInfo={false}></RubricDetail> */}
          </ModalBody>
          <ModalFooter>
            <Button
              color="primary"
              onClick={() => this.hideModalViewRubric(item.id)}
            >
              Xong
            </Button>{' '}
          </ModalFooter>
        </Modal>
      </div>
    );
  }

  showModalMarkRubric(itemId) {
    const { showModalMarkRubric } = this.state;
    showModalMarkRubric[itemId] = true;
    this.setState({
      showModalMarkRubric: showModalMarkRubric,
    });
  }

  hideModalMarkRubric(itemId) {
    const { showModalMarkRubric } = this.state;
    showModalMarkRubric[itemId] = false;
    this.setState({
      showModalMarkRubric: showModalMarkRubric,
    });
  }

  onMarkRubricDone = async (itemId) => {
    const markRubricData = markRubricRef.getRubricData();
    await this.props.onMarkRubricDone(itemId, markRubricData);
    this.hideModalMarkRubric(itemId);
  };

  onMarkNoneRubricDone = async (itemId) => {
    await this.props.onMarkRubricDone(itemId);
  };

  // showRubricMark(item) {
  //   const response = item.response ? JSON.parse(item.response) : false;
  //   const markRubric = item.mark_rubric ? JSON.parse(item.mark_rubric) : false;
  //   let rubric;
  //   if (response.type == 'rubric') {
  //     rubric = response.rubric;
  //     /*  if (typeof rubric.criteria == 'string') {
  //        rubric.criteria = JSON.parse(rubric.criteria)
  //      } */
  //   }

  //   if (response) {
  //     return (
  //       <div>
  //         {rubric.criteria && rubric.criteria.usePoint && <span>Điểm đạt được: {parseFloat(item.point).toFixed(1)}<small>/{item.point_max} </small> ({parseFloat(item.point / item.point_max * 100).toFixed(0)}%)</span>}
  //         <Button size="sm" className={(item.mark_rubric ? 'btn-danger' : 'btn-success') + ' float-right'} onClick={() => this.showModalMarkRubric(item.id)} >{item.mark_rubric ? 'Chấm lại' : 'Chấm điểm'}</Button>
  //         <Modal size="xxl" isOpen={this.state.showModalMarkRubric[item.id]} toggle={() => this.hideModalMarkRubric(item.id)} centered>
  //           <ModalHeader toggle={() => this.hideModalMarkRubric(item.id)}>{rubric.name}</ModalHeader>
  //           <ModalBody>
  //             <RubricMark ref={(element) => { markRubricRef = element }} rubric={rubric} markRubric={markRubric} showName={false} showInfo={false}></RubricMark>
  //           </ModalBody>
  //           <ModalFooter>
  //             <Button color="primary" onClick={() => this.onMarkRubricDone(item.id)}>Hoàn thành</Button>{' '}
  //           </ModalFooter>
  //         </Modal>

  //       </div>
  //     )
  //   } else {
  //     return (
  //       <div>
  //         <Button size="sm" color={item.status == 0 ? 'success' : 'danger'} className="float-right" onClick={() => this.onMarkNoneRubricDone(item.id)}>{item.status == 0 ? 'Chấm điểm' : 'Chấm lại'}</Button>
  //       </div>
  //     )
  //   }
  // }

  render() {
    const useRaw = this.props.useRaw || false;
    const { item } = this.props;
    const haveItemRubric = item.response !== '""' && item.response !== '';

    const childs = this.props.childs || false;
    const hideChoices = this.props.hideChoices || false;
    let itemBody;
    let choices = {};
    if (!useRaw) {
      itemBody = item.data_body_html ? item.data_body_html : item.body;
      try {
        choices = JSON.parse(
          item.data_choices_html ? item.data_choices_html : item.choices,
        );
      } catch (e) {}
      if (typeof choices != 'object' || choices == null) {
        choices = {};
      }
    } else {
      itemBody = item.body;
      try {
        choices = JSON.parse(item.choices);
      } catch (e) {}
      if (typeof choices != 'object' || choices == null) {
        choices = {};
      }
    }

    let itemNumber = this.props.itemNumber >= 0 ? this.props.itemNumber : 1;
    item.id = item.id ? item.id : 0;

    return (
      <Card className="layout-item x-card mb-4">
        <Row className={this.props.className}>
          <Col
            sm="12"
            className={`question-body mt-1 ${
              itemBody || item.is_parent == 1 ? '' : ' item-body-empty'
            }`}
          >
            <ScrollElement name={`ScrollToItem${item.id}`} />
            {item.is_parent != 1 && (
              <QuestionDetail
                hideChoices={hideChoices}
                item={item}
                itemNumber={itemNumber}
                choices={choices}
                hideResponse={this.props.hideResponse}
                itemBody={itemBody}
              />
            )}
          </Col>

          {childs &&
            childs.map((itemChild, indexChild) => {
              let childChoices = {};
              const haveItemChildRubric =
                itemChild.response !== '""' && itemChild.response !== '';
              let itemChildBody;
              if (!useRaw) {
                itemChildBody = itemChild.data_body_html
                  ? itemChild.data_body_html
                  : itemChild.body;
                try {
                  childChoices = JSON.parse(
                    itemChild.data_choices_html
                      ? itemChild.data_choices_html
                      : itemChild.choices,
                  );
                } catch (e) {}
                if (typeof childChoices != 'object' || childChoices == null) {
                  childChoices = {};
                }
              } else {
                itemChildBody = itemChild.body;
                try {
                  childChoices = JSON.parse(itemChild.choices);
                } catch (e) {}
                if (typeof childChoices != 'object' || childChoices == null) {
                  childChoices = {};
                }
              }

              itemChild.id = itemChild.id ? itemChild.id : 0;
              itemNumber += 1;

              return (
                <Col
                  id={`item-${itemChild.id}`}
                  sm="12"
                  className={`question-body mt-1 ${
                    itemChildBody || itemChild.is_parent == 1
                      ? ''
                      : ' item-body-empty'
                  }`}
                  key={indexChild}
                >
                  <ScrollElement name={`ScrollToItem${itemChild.id}`} />
                  <QuestionDetail
                    hideChoices={hideChoices}
                    item={itemChild}
                    itemBody={itemChildBody}
                    itemNumber={itemNumber}
                    choices={childChoices}
                    hideResponse={this.props.hideResponse}
                  />
                  {this.props.showChildItemInfo && (
                    <p className="mt-1">
                      {itemChild.test_blueprint_name &&
                        itemChild.test_blueprint_id > 2 && (
                          <span className="badge badge-light  badge-pill mr-1">
                            <i className="fa fa-caret-right" />{' '}
                            {itemChild.test_blueprint_name}
                          </span>
                        )}
                      {/*  {itemChild.difficult_level > 0 && (
                        <span className="badge badge-light  badge-pill mr-1">
                          {config.getConfigNameByValue(
                            'item_difficult_levels',
                            itemChild.difficult_level,
                          )}
                        </span>
                      )}
                      {itemChild.level > 0 && !this.props.showItemId && (
                        <span className="badge badge-light  badge-pill">
                          {config.getConfigNameByValue(
                            'item_levels',
                            itemChild.level,
                          )}
                        </span>
                      )} */}
                    </p>
                  )}
                  {this.props.showInstruction && (
                    <div className="mt-1">
                      <p>
                        <b>Hướng dẫn giải:</b>{' '}
                      </p>
                      {itemChild.type == 3 && haveItemChildRubric ? (
                        this.showRubricDetail(itemChild)
                      ) : (
                        <div
                          className="bg-light p-2"
                          dangerouslySetInnerHTML={{
                            __html:
                              itemChild.instruction ||
                              '<p class="text-center"><i>Chưa có hướng dẫn giải</i></p>',
                          }}
                        />
                      )}
                    </div>
                  )}
                  {this.props.showRubricMark && itemChild.type == 3 && (
                    <div className="mt-2">
                      {/* {this.showRubricMark(itemChild)} */}
                    </div>
                  )}
                </Col>
              );
            })}
        </Row>

        {item.is_parent != 1 && this.props.showInstruction && (
          <div className="mt-2">
            <p>
              <b>Hướng dẫn giải:</b>{' '}
            </p>
            {item.type == 3 && haveItemRubric ? (
              this.showRubricDetail(item)
            ) : (
              <div
                className="bg-light p-2"
                dangerouslySetInnerHTML={{
                  __html:
                    item.instruction ||
                    '<p class="text-center"><i>Chưa có hướng dẫn giải</i></p>',
                }}
              />
            )}
          </div>
        )}
        {this.props.showRubricMark && item.is_parent != 1 && item.type == 3 && (
          <Row className="mt-2">
            <Col sm={12}>{/* {this.showRubricMark(item)} */}</Col>
          </Row>
        )}
      </Card>
    );
  }
}

export default ViewItem;
