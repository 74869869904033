import React, { useEffect, useState } from 'react';
import { getMatchClozeText, replaceDatasetValue } from 'app/utils/functions'
import 'material-design-icons/iconfont/material-icons.css';

export default function ClozeTextQuestionView(props) {

  const { item, itemNumber, itemBody } = props;
  const [bodyHtml, setBodyHtml] = useState('');
  useEffect(()=>{
    if(!item) {
      return
    }
    let body = structuredClone(item.body);
    body = replaceDatasetValue(body, item?.render_options)
    const awnserBody = getMatchClozeText(item.body)
    const awnser = item.answer ? JSON.parse(item.answer) : null;
    const dataResponse = item.data_response ? JSON.parse(item.data_response) : null;

    for (let index = 0; index < awnserBody.length; index++) {
      let fillAnwser = '';
      let checkAnwser = false;
      if( dataResponse && dataResponse[index] ) {
        
        if( awnser && awnser[index]) {
          fillAnwser = `${awnser[index]}`
          if(awnser[index] == dataResponse[index]) {
            checkAnwser = true;
          }
        }
        else {
          fillAnwser = `[Bỏ qua]`
        }
      }
      fillAnwser = `<span class='${checkAnwser ? 'correct' : 'wrong'} cloze_anwser'> ${fillAnwser} </span>`
      body = body.replace(awnserBody[index], fillAnwser)
    }
    setBodyHtml(body)

  },[item])

  return (
    <div className="avoid-break mb-2 flex">
			<b
				className={
				itemBody || item?.is_parent == 1
						? ' mr-2'
						: ' item-num-empty mr-2'
				}
			>
				{itemNumber}.{' '}
			</b>
			<span
				dangerouslySetInnerHTML={{ __html: bodyHtml || '' }}
				id={`_item_${item?.id}_body`}
			/>
		</div>
  );
}


export { ClozeTextQuestionView };
