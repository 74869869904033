import React, { useEffect, useState } from 'react';

import { Row, Space, Radio, Col } from 'antd';

export default function MatchTableQuestionView(props) {

  const { item } = props;
  const [data, setData] = useState(null);
  const [options, setOptions] = useState(null);

  useEffect(()=>{
    if(!item) {
      return
    }
    const itemChoice = JSON.parse(item.choices)
    const contents = itemChoice[0];
    const shuffe = itemChoice[2];
    const response = JSON.parse(item.response);
    const answer = item.answer ? JSON.parse(item.answer) : null;
    let convertOptions = itemChoice[1];
    let arrayContents = [];
    for (const order of shuffe) {
      for (const [key, value] of Object.entries(contents)) {
        if(key == order) {
          arrayContents.push({
            content:value,
            options: convertOptions,
            answer: answer ? answer[key] : null,
            response: response[key]
          });
        }
      }
    }
    setData(arrayContents)
    setOptions(convertOptions)
  },[item])

  return (
    <Space direction="vertical" className="table-responsive" style={{ width: '100%' }}>
      <table
        style={{ width: '100%' }}
        className="table table-bordered match-table-list-question"
      >
        <thead></thead>
        <tbody>
          <tr>
            <td style={{ border: 'none' }}></td>
            {
              !options ? (<td/>) : Object.values(options).map(function(key, index) {

                return (
                  <td key={'options'+ item.id + index} className="text-center" style={{ border: 'none', width: '80px' }}>{key}</td>
                )
              })  
            }
            
          </tr>
          {
            !data ? (<tr/>) : data.map((e, index) => {
              return (
                <tr key={'load_tr_'+ index}>
                  <td>
                    <span dangerouslySetInnerHTML={{ __html: e.content }} >
                    </span>
                  </td>
                  {
                    !e.options ? (<td/>) : Object.keys(e.options).map(function(key, index) {

                      return (
                        <td key={'load_td_'+ index} className="text-center">
                          <Radio
                            className={`${e.response == e.answer ? 'correct' : 'wrong'}`}
                            checked={
                              key == e.answer ? true : false
                            }
                          />
                        </td>
                      )
                    })  
                  }
                </tr>
              )
            })
          }

        </tbody>
        
      </table>
    </Space>
  );
}


export { MatchTableQuestionView };
