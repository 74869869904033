import React from 'react'

const BodyQuestion = (props) => {

	const { itemBody, item, itemNumber } = props

	return (
		<div className="avoid-break mb-2 flex">
			<b
				className={
				itemBody || item.is_parent == 1
						? ' mr-2'
						: ' item-num-empty mr-2'
				}
			>
				{itemNumber}.{' '}
			</b>
			<span
				dangerouslySetInnerHTML={{ __html: itemBody || '' }}
				id={`_item_${item.id}_body`}
			/>
		</div>
	)
}

export default BodyQuestion;