import React, { useEffect, useState } from 'react';

export default function PreviewArrangeWordsQuestion(props) {

  const { item } = props;
  const [choicesHtml, setChoicesHtml] = useState(null);
  const [answerHtml, setAnswerHtml] = useState(null);
  const [statusAnswer, setStatusAnswer] = useState(false);
  useEffect(()=>{
    if(!item) {
      return
    }
    let answers = item.answer ? JSON.parse(item.answer) : null;
    let choices = item.choices ? JSON.parse(item.choices) : null;
    setChoicesHtml(choices);
    if(answers.length > 0) {
      let arrayAnswers = [];
      for (const key of answers) {
        arrayAnswers.push(choices[key])
      }
      setAnswerHtml(arrayAnswers)
    }
    setStatusAnswer(item.status_answer ?? false)
    
  },[item])

  return (
    <>
      <div className="avoid-break mb-2 flex">
        {!choicesHtml ? '' : Object.values(choicesHtml).map((e, index) => {

          return (
            <span key={e + index} className="arrange-words__preview_item arrange-words__preview_item" dangerouslySetInnerHTML={{ __html: e }}></span>
          );
        })}
      </div>
      <div className="mb-2">Bài làm:</div>
      <div className="avoid-break mb-2 flex">
        {!answerHtml ? (
          <span className='wrong cloze_anwser'> [Bỏ qua] </span>
        ) : answerHtml.map((e, index) => {
          if(e) {
            return (
              <span key={'question'+ item.id + index} className={`${statusAnswer ? 'correct' : 'wrong'} arrange-words__preview_item arrange-words__preview_item`} dangerouslySetInnerHTML={{ __html: e }}></span>
            );
          }

          return (
            <span key={'question'+ item.id + index} className={`skip arrange-words__preview_item arrange-words__preview_item`}>Bỏ qua</span>
          );
        })}
      </div>
      
    </>
    
  );
}


export { PreviewArrangeWordsQuestion };
